import {
  REPORTS_LIST_RESPONSE,
  REPORTS_SEARCH,
  REPORTS_UI_FILTER,
  ReportsActionTypes,
  ReportStatus,
  ReportsUiState,
  REPORTS_YEAR,
  REPORTS_MONTH
} from "./reports.types";

const initialState = {
  filter: ('AVAILABLE' as ReportStatus),
  search: '',
  year: (new Date().getFullYear()).toString(),
  month: (new Date().getMonth()).toString()
}

export const reportsUiReducer = (state: ReportsUiState = initialState, action: ReportsActionTypes) => {
  switch (action.type) {
    case REPORTS_LIST_RESPONSE:
      return {
        ...state,
        search: ''
      }
      case REPORTS_UI_FILTER:
        return {
          ...state,
          filter: action.payload.filter
        }
        case REPORTS_YEAR:
          return {
            ...state,
            year: action.payload.year,
          };
        case REPORTS_MONTH:
          return {
            ...state,
            month: action.payload.month,
          };
    case REPORTS_SEARCH:
      return {
        ...state,
        search: action.payload.search
      }
    default:
      return state;
  }
}
