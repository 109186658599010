import axios from 'axios';

const BASE_URL = '/api';

export let apiInstance = axios.create({
  baseURL: BASE_URL,
});

function withToken(token: string) {
  return axios.create({
    baseURL: BASE_URL,
    headers: {
      'x-auth-token': token,
    },
  });
}

export function setAuthToken(token: string | null): void {
  if (token === null) {
    apiInstance = axios.create({
      baseURL: BASE_URL,
    });
  } else {
    apiInstance = withToken(token);
  }
}
