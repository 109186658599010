import { PasswordMismatchError, UnauthorizedError } from 'core/util/errors';

import { AxiosResponse } from 'axios';
import { parseISO } from 'core/util/date';
import { setAuthToken, apiInstance } from './axios';
import { convertPhysician } from './transformers/physicians.transformer';

export async function init() {
  // User is stored in localStorage as { user: userID, session: sessionToken }
  const storedSession = localStorage.getItem('schedulerSession');
  if (storedSession) {
    const { token, user } = JSON.parse(storedSession);
    setAuthToken(token);
    return validate(user)
      .catch(e => {
        console.warn('No valid session token present');
        throw e;
      });
  }
  throw new Error('No session token stored');
}

export function validate(user): Promise<UserAPI> {
  return apiInstance
    .get<PhysicianGetAPI>(`physicians/${user}`)
    .then(result => {
      return convertPhysician(result.data.physician);
    })
    .catch(e => {
      setAuthToken(null);
      return Promise.reject(e);
    });
}

export function editUser(firstname: string, lastname: string, email: string, locale: string) {
  return apiInstance
    .patch(`users`, {
      first_name: firstname,
      last_name: lastname,
      email,
      language_preference: locale,
    })
    .then(result => result.data);
}

export function changePassword(oldPassword: string, newPassword: string, passwordConfirmation: string): Promise<void> {
  if (newPassword === passwordConfirmation) {
    return apiInstance
      .patch('physicians/password', {
        old_password: oldPassword,
        new_password: newPassword,
        confirm_password: passwordConfirmation,
      })
      .then(result => {
        setAuthToken(result.data['x-auth-token']);
        return result.data;
      })
      .catch(error => {
        if (error.response.status === 401) {
          throw new UnauthorizedError();
        }
        throw error;
      });
  }
  throw new PasswordMismatchError();
}

export async function login(email: string, password: string) {
  let authToken: string;
  try {
    const loginResponse = await apiInstance.post<LoginResponse>('physicians/login', { email, password });
    authToken = loginResponse.data['x-auth-token'];
    const physician = loginResponse.data.physician_id;
    setAuthToken(authToken);
    localStorage.setItem(
      'schedulerSession',
      JSON.stringify({ user: physician, token: authToken, saved_at: new Date().toISOString() })
    );
    const userResponse = await apiInstance.get<PhysicianGetAPI>(`physicians/${physician}`);
    return convertPhysician(userResponse.data.physician);
  } catch (error) {
    if (error.response && error.response.status === 401) {
      throw new UnauthorizedError();
    }
    throw error;
  }
}

export function forgotPassword(email: string) {
  return apiInstance
    .post('physicians/password/forgot', { email })
    .then(result => result.data);
}

// tslint:disable-next-line: variable-name
export function resetPassword(token: string, new_password: string, confirm_password: string) {
  return apiInstance
    .post('users/password/reset', { new_password, confirm_password, token })
    .then(response => {
      return response.data;
    });
}

export async function logout(): Promise<void> {
  setAuthToken(null);
  localStorage.removeItem('schedulerSession');
  return;
  // return apiInstance.post('users/logout').then(() => {
  // });
}

export function fetchPhysiciansList() {
  return apiInstance.get('physicians')
    .then(response => {
      return response.data.physicians.map(convertPhysician);
    })
}

export function postPhysician(body: Partial<PhysicianAPI>, center) {
  return apiInstance.post(`physicians/centre/${center}`, body)
    .then(response => {
      return convertPhysician(response.data.physician);
    })
}
