import { combineReducers } from 'redux'
import { systemReducer } from './system/system.reducer';
import { SystemState, LOGOUT_RESPONSE } from './system/system.types';
import { reportsEntitiesReducer } from './reports/reportsEntities.reducer';
import { reportsDomainReducer } from './reports/reportsDomain.reducer';
import { ReportsDomainState, ReportsEntityState, ReportsUiState } from './reports/reports.types';
import { PhysiciansEntityState } from './physicians/physicians.types';
import { physiciansEntitiesReducer } from './physicians/physiciansEntities.reducer';
import { reportsUiReducer } from './reports/reportsUi.reducer';

export interface AppState {
  system: SystemState;
  reportsDomain: ReportsDomainState;
  entities: {
    reports: ReportsEntityState;
    physicians: PhysiciansEntityState;
  },
  ui: {
    reports: ReportsUiState;
  }
}

const uiReducer = combineReducers({
  reports: reportsUiReducer
})

const entityReducer = combineReducers({
  reports: reportsEntitiesReducer,
  physicians: physiciansEntitiesReducer
})

export const appReducer = combineReducers({
  system: systemReducer,
  reportsDomain: reportsDomainReducer,
  entities: entityReducer,
  ui: uiReducer
})

export const rootReducer = (state, action) => {
  if (action.type === LOGOUT_RESPONSE) {
    state = undefined
  }

  return appReducer(state, action)
}