import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';

import { rootReducer } from 'store';
import * as mw from 'store/middlewares';
import './styles/main.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './core/i18n';
import { initSystemThunk } from 'store/system/system.actions';

const middlewares = [thunk, mw.crashReporter];

if (__DEV__) {
  const {createLogger} = require(`redux-logger`);
  const logger = createLogger();
  middlewares.push(logger);
}

function initStore() {
  const store = createStore(rootReducer, applyMiddleware(...middlewares));
  store.dispatch(initSystemThunk());
  return store; 
}

function renderApp(store) {
  ReactDOM.render(
    <BrowserRouter>
      <Provider store={store}>
        <React.Fragment>
          <App />
        </React.Fragment>
      </Provider>
    </BrowserRouter>,
    document.getElementById('root')
  );
}

renderApp(initStore());

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
