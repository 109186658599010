import { keyBy, concat } from 'lodash';
import { combineReducers } from 'redux';
import {
  ReportsActionTypes,
  REPORTS_LIST_RESPONSE,
  REPORTS_GET_RESPONSE,
  REPORTS_POST_COMMENTS_RESPONSE,
  REPORTS_POST_PROCESS_RESPONSE,
  REPORTS_POST_PRESCRIPTION_RESPONSE
} from './reports.types';

const byIdReducer = (state = {}, action: ReportsActionTypes) => {
  switch (action.type) {
    case REPORTS_LIST_RESPONSE:
      if (!action.payload.error && action.payload.reports) {
        return {
          ...state,
          ...keyBy(action.payload.reports, (report) => report.id),
        };
      }
      return state;
    case REPORTS_GET_RESPONSE:
      if (!action.payload.error && action.payload.report) {
        return {
          ...state,
          [action.payload.report.id]: action.payload.report
        };
      }
      return state;
    case REPORTS_POST_COMMENTS_RESPONSE:
      if (!action.payload.error && action.payload.report) {
        return {
          ...state,
          [action.payload.report.id]: {
            ...state[action.payload.report.id],
            comments: action.payload.report.comments,
            processedBy: action.payload.user
          }
        };
      }
      return state;
    case REPORTS_POST_PRESCRIPTION_RESPONSE:
      if (!action.payload.error && action.payload.report) {
        return {
          ...state,
          [action.payload.report.id]: {
            ...state[action.payload.report.id],
            prescription: action.payload.report.prescription,
            processedBy: action.payload.user
          }
        };
      }
      return state;
    case REPORTS_POST_PROCESS_RESPONSE:
      if (!action.payload.error && action.payload.report) {
        return {
          ...state,
          [action.payload.report.id]: {
            ...state[action.payload.report.id],
            status: action.payload.report.status
          }
        };
      }
    default:
      return state;
  }
};

const allIdsReducer = (state = [], action: ReportsActionTypes) => {
  switch (action.type) {
    case REPORTS_LIST_RESPONSE:
      if (!action.payload.error && action.payload.reports) {
        return action.payload.reports.map(report => report.id);
      }
      return state;
    case REPORTS_GET_RESPONSE:
      if (!action.payload.error && action.payload.report) {
        return concat(state, action.payload.report.id);
      }
      return state;
    default:
      return state;
  }
};

export const reportsEntitiesReducer = combineReducers({
  byId: byIdReducer,
  allId: allIdsReducer
});
