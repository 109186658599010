import { keyBy } from 'lodash';
import { PhysiciansActionTypes, PHYSICIANS_LIST_RESPONSE } from "./physicians.types";
import { combineReducers } from 'redux';

const byIdReducer = (state = {}, action: PhysiciansActionTypes) => {
  switch (action.type) {
    case PHYSICIANS_LIST_RESPONSE:
        if (!action.payload.error && action.payload.physicians) {
          return keyBy(action.payload.physicians, report => report.id)
        }
        return state;
    default:
      return state;
  }
}

const allIdsReducer = (state = [], action: PhysiciansActionTypes) => {
  switch (action.type) {
    case PHYSICIANS_LIST_RESPONSE:
        if (!action.payload.error && action.payload.physicians) {
          return action.payload.physicians.map(physician => physician.id)
        }
        return state;
    default:
        return state;
  }
}

export const physiciansEntitiesReducer = combineReducers({
  byId: byIdReducer,
  allId: allIdsReducer
});
