import { Physician } from "store/system/system.types";

export interface PhysiciansEntityState extends NormalizedState<Physician> {}

export interface PhysiciansDomainState {
  fetchingList: boolean;
}

export const PHYSICIANS_LIST_REQUEST = 'PHYSICIANS_LIST_REQUEST';
export const PHYSICIANS_LIST_RESPONSE = 'PHYSICIANS_LIST_RESPONSE';
export const PHYSICIANS_POST_REQUEST = 'PHYSICIANS_POST_REQUEST';
export const PHYSICIANS_POST_RESPONSE = 'PHYSICIANS_POST_RESPONSE';

interface PhysiciansListRequestAction {
  type: typeof PHYSICIANS_LIST_REQUEST;
}

interface PhysiciansListResponseAction {
  type: typeof PHYSICIANS_LIST_RESPONSE;
  payload: {
    error?: any;
    physicians: Physician[] | null;
  };
}

interface PhysiciansPostRequestAction {
  type: typeof PHYSICIANS_POST_REQUEST;
}

interface PhysiciansPostResponseAction {
  type: typeof PHYSICIANS_POST_RESPONSE;
  payload: {
    error?: any;
    physician: Physician | null;
  };
}

export type PhysiciansActionTypes =
  | PhysiciansListRequestAction
  | PhysiciansListResponseAction
  | PhysiciansPostRequestAction
  | PhysiciansPostResponseAction
