import { Physician } from "store/system/system.types";

export const convertPhysician = (physician: PhysicianAPI): Physician => {
  return {
    id: physician._id,
    firstname: physician.first_name,
    lastname: physician.last_name,
    email: physician.email,
    phone: physician.phone_number,
    language: physician.language_preference,
    masterPhysician: physician.master_physician
  }
}