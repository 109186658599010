import { Patient } from "store/reports/reports.types";

export const convertPatient = (patient: PatientAPI): Patient => {
  return {
    id: patient._id,
    firstname: patient.first_name || '',
    lastname: patient.last_name || '',
    name: `${patient.first_name || ''} ${patient.last_name || ''}`,
    email: patient.email,
  }
}