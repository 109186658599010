import { Report } from "store/reports/reports.types";
import moment from "moment";
import { convertPatient } from "./patients.transformer";
import { isArray } from "util";
import { convertPhysician } from "./physicians.transformer";

export const convertReport = (report: ReportAPI): Report => {
  try{
  return {
    id: report._id,
    status: report.status,
    centre: report.centre,
    patient: convertPatient(report.patient),
    testId: report.test_id,
    testReport: report.test_report,
    recordingReports: report.recording_reports,
    comments: report.comments || "",
    allocatedAt: moment(report.allocated_at, moment.ISO_8601).toDate(),
    processedBy: report.processed_by
      ? convertPhysician(report.processed_by)
      : undefined,
    processedAt: moment(report.processed_at, moment.ISO_8601).toDate(),
    resmedOptIn: report.resmed_opt_in,
    content: report.content || null,
    service: report.service || null,
    prescription: report.prescription || "",
  };
  } catch (e) {
    return null;
  }
};
