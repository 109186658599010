import moment from 'moment';
import { apiInstance } from './axios';
import { convertReport } from './transformers/reports.transformer';

export const fetchReportsList = async (status,year,month) => {
  try {
  let query: any = {};
  if(status){
    query.status = status;
  }
  if(year&&month){
    const startDate = moment([year, month, 1]).format('YYYY-MM-DD');
    const endDate = moment([year, month, 1]).add(1, 'month').format('YYYY-MM-DD');

    query = {
      ...query,
      createdAt: {
        $gte:new Date(startDate + 'T00:00:00'),
        $lt:new Date(endDate + 'T00:00:00'),
      },
    };
  }
  const params: any = {
    ...query,
  };
    const reportsResponse = await apiInstance.get('reports',{params});
    return reportsResponse.data.reports.map(convertReport).filter((el) => el);
  } catch (e) {
    throw e;
  }
};

export const fetchReport = async id => {
  try {
    const reportResponse = await apiInstance.get(`reports/${id}`);
    return convertReport(reportResponse.data.report);
  } catch (e) {
    throw e;
  }
};

export const saveComments = async (id: string, comments: string) => {
  try {
    await apiInstance.post(`reports/comment/${id}`, { comments, file: 'file' });
  } catch (e) {
    throw e;
  }
};

export const savePrescription = async (id: string, prescription: string) => {
  try {
    await apiInstance.post(`reports/prescription/${id}`, { prescription });
  } catch (e) {
    throw e;
  }
};

export const processReport = async (id: string, status: 'APPROVED' | 'REJECTED') => {
  try {
    const response = await apiInstance.post(`reports/process/${id}`, { status });
  } catch (e) {
    throw e;
  }
};

export const getFile = async file => {
  try {
    const fileResponse = await apiInstance.get('/reports/file', {
      params: {
        key: file
      }
    });
    return fileResponse.data.file_url;
  } catch (e) {
    throw e;
  }
};
