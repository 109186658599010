import { Physician } from "store/system/system.types";

export interface ReportsEntityState {
  byId: { [key: string]: Report };
  allIds: string[];
}

export interface ReportsDomainState {
  availableReports: string[];
  approvedReports: string[];
  rejectedReports: string[];
  fetchingList: boolean;
  fetchingAvailableList: boolean;
  fetchingApprovedList: boolean;
  fetchingRejectedList: boolean;
  fetchingGet: boolean;
  fetchingProcessing: boolean;
}

export interface ReportsUiState {
  filter: ReportStatus;
  search: string;
  year: string;
  month: string;
}

export type ReportStatus = "AVAILABLE" | "APPROVED" | "REJECTED";

export interface Patient {
  id: string;
  firstname: string;
  lastname: string;
  name: string;
  email: string;
}

export interface Report {
  id: string;
  status: ReportStatus;
  comments: string;
  patient: Patient;
  testId: string;
  testReport: {
    surveys: any;
    file: string;
  };
  recordingReports: {
    surveys: any;
    file: string;
  }[];
  centre: string;
  allocatedAt: Date;
  processedBy?: Physician;
  processedAt: Date;
  resmedOptIn: Boolean;
  content?: any;
  service?: string;
  prescription: string
}

export const REPORTS_LIST_REQUEST = "REPORTS_LIST_REQUEST";
export const REPORTS_LIST_RESPONSE = "REPORTS_LIST_RESPONSE";
export const REPORTS_GET_REQUEST = "REPORTS_GET_REQUEST";
export const REPORTS_GET_RESPONSE = "REPORTS_GET_RESPONSE";
export const REPORTS_POST_COMMENTS_REQUEST = "REPORTS_POST_COMMENTS_REQUEST";
export const REPORTS_POST_COMMENTS_RESPONSE = "REPORTS_POST_COMMENTS_RESPONSE";
export const REPORTS_POST_PRESCRIPTION_REQUEST = "REPORTS_POST_PRESCRIPTION_REQUEST";
export const REPORTS_POST_PRESCRIPTION_RESPONSE = "REPORTS_POST_PRESCRIPTION_RESPONSE";
export const REPORTS_POST_PROCESS_REQUEST = "REPORTS_POST_PROCESS_REQUEST";
export const REPORTS_POST_PROCESS_RESPONSE = "REPORTS_POST_PROCESS_RESPONSE";
export const REPORTS_SEARCH = "REPORTS_SEARCH";

export const REPORTS_UI_FILTER = "REPORTS_UI_FILTER";
export const REPORTS_YEAR = "REPORTS_YEAR";
export const REPORTS_MONTH = "REPORTS_MONTH";

interface ReportsListRequestAction {
  type: typeof REPORTS_LIST_REQUEST;
  payload: {
    filter: ReportStatus | null;
  };
}

interface ReportsListResponseAction {
  type: typeof REPORTS_LIST_RESPONSE;
  payload: {
    error?: any;
    reports: Report[] | null;
    filter: ReportStatus | null;
  };
}

interface ReportsGetRequestAction {
  type: typeof REPORTS_GET_REQUEST;
}

interface ReportsGetResponseAction {
  type: typeof REPORTS_GET_RESPONSE;
  payload: {
    error?: any;
    report: Report | null;
  };
}

interface ReportsPostCommentsRequestAction {
  type: typeof REPORTS_POST_COMMENTS_REQUEST;
}

interface ReportsPostPrescriptionRequestAction {
  type: typeof REPORTS_POST_PRESCRIPTION_REQUEST;
}

interface ReportsPostCommentsResponseAction {
  type: typeof REPORTS_POST_COMMENTS_RESPONSE;
  payload: {
    error?: any;
    report?: {
      id: string;
      comments: string;
      user?: Physician;
    };
  };
}


interface ReportsPostPrescriptionResponseAction {
  type: typeof REPORTS_POST_PRESCRIPTION_RESPONSE;
  payload: {
    error?: any;
    report?: {
      id: string;
      prescription: string;
      user?: Physician;
    };
  };
}

interface ReportsPostProcessRequestAction {
  type: typeof REPORTS_POST_PROCESS_REQUEST;
}

interface ReportsPostProcessResponseAction {
  type: typeof REPORTS_POST_PROCESS_RESPONSE;
  payload: {
    error?: any;
    report?: {
      id: string;
      status: "APPROVED" | "REJECTED";
    };
  };
}

interface ReportsUiFilterAction {
  type: typeof REPORTS_UI_FILTER;
  payload: {
    filter: ReportStatus;
  };
}

interface ReportsYearAction {
  type: typeof REPORTS_YEAR;
  payload: {
    year: string;
  };
}

interface ReportsMonthAction {
  type: typeof REPORTS_MONTH;
  payload: {
    month: string;
  };
}

interface ReportsSearchAction {
  type: typeof REPORTS_SEARCH;
  payload: {
    search: string;
    reports: ReportsEntityState["byId"];
  };
}

export type ReportsActionTypes =
  | ReportsListRequestAction
  | ReportsListResponseAction
  | ReportsGetRequestAction
  | ReportsGetResponseAction
  | ReportsPostCommentsRequestAction
  | ReportsPostCommentsResponseAction
  | ReportsPostPrescriptionRequestAction
  | ReportsPostPrescriptionResponseAction
  | ReportsPostProcessRequestAction
  | ReportsPostProcessResponseAction
  | ReportsUiFilterAction
  | ReportsYearAction
  | ReportsMonthAction
  | ReportsSearchAction;
