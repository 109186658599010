import React from 'react';

import '../styles.scss';

interface Props {
  title: string;
  footer?: () => React.ReactNode;
}

export const Card: React.FunctionComponent<Props> = ({
  title,
  footer,
  children,
}) => {
  return (
    <div className='card'>
      <div className='card-header'>
        <div className='text-center'>{title}</div>
      </div>
      <div className='card-body'>{children}</div>
      {footer && footer()}
    </div>
  );
};
