import { ThunkAction } from 'redux-thunk';
import { AppState } from 'store';
import { api } from 'api';
import { SystemActionTypes, INIT_SYSTEM, LOGIN_REQUEST, LOGIN_RESPONSE, LOGOUT_RESPONSE, Physician, PASSWORD_CHANGE_REQUEST, PASSWORD_CHANGE_RESPONSE } from './system.types';

export const initSystemThunk = (): ThunkAction<void, AppState, null, SystemActionTypes> => async dispatch => {
  try {
    const user = await api.physicians.init();
    dispatch(initSystem({ authenticated: true, user }));
  } catch (e) {
    dispatch(initSystem({ authenticated: false }));
  }
};

const initSystem = (payload): SystemActionTypes => {
  return {
    type: INIT_SYSTEM,
    payload
  };
};

export const loginThunk = (payload: {email: string; password: string}): ThunkAction<void, AppState, null, SystemActionTypes> => async dispatch => {
  dispatch(loginRequest());
  try {
    const user = await api.physicians.login(payload.email, payload.password);
    dispatch(loginResponse(null, user));
  } catch (e) {
    console.warn(e);
    dispatch(loginResponse(e))
  }

};

const loginRequest = (): SystemActionTypes => {
  return {
    type: LOGIN_REQUEST
  }
}

const loginResponse = (error, user?: Physician): SystemActionTypes => {
  return {
    type: LOGIN_RESPONSE,
    payload: {
      error,
      user
    }
  }
}

export const logoutThunk = (): ThunkAction<void, AppState, null, SystemActionTypes> => async dispatch => {
  await api.physicians.logout();
  dispatch(logoutResponse());
}

const logoutResponse = (): SystemActionTypes => {
  return {
    type: LOGOUT_RESPONSE
  }
}

export const passwordChangeThunk = (payload: {oldPassword, password, passwordConfirm}): ThunkAction<void, AppState, null, SystemActionTypes> => async dispatch => {
  dispatch(passwordChangeRequest());
  try {
    await api.physicians.changePassword(payload.oldPassword, payload.password, payload.passwordConfirm);
    dispatch(passwordChangeResponse(null));
  } catch (e) {
    console.warn(e);
    dispatch(passwordChangeResponse(e))
  }
}

const passwordChangeRequest = (): SystemActionTypes => {
  return {
    type: PASSWORD_CHANGE_REQUEST
  }
}

const passwordChangeResponse = (error): SystemActionTypes => {
  return {
    type: PASSWORD_CHANGE_RESPONSE,
    payload: {
      error
    }
  }
}