import React, { Component, lazy, Suspense } from 'react';
import { RouteProps } from 'react-router';
import { Redirect, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import Login from 'containers/Auth/views/Login';
import { AppState } from 'store';

// import ForgotPassword from './containers/Auth/ForgotPassword';
// import Invitation from './containers/Auth/Invitation';
// import Login from './containers/Auth/Login';
// import ResetPassword from './containers/Auth/ResetPassword';
// import { FullscreenSpinner } from './containers/FullscreenSpinner';
// import { SurveyContainer } from './containers/Survey';
// import { onLanguageChange } from './core/i18n';
// import { AuthStoreType } from './core/stores/auth';

const Application = lazy(() => import('containers/Application'));

interface InjectedProps {
  initialized: boolean;
  authenticated: boolean;
}

type Props = InjectedProps & RouteProps;

class App extends Component<Props, {}> {
  onLanguageChangeListener: any;

  state = {
    languageChange: false
  };

  componentDidMount() {
    // this.onLanguageChangeListener = onLanguageChange(this.onLanguageChange);
  }

  componentWillUnmount() {
    // this.onLanguageChangeListener.remove();
  }

  onLanguageChange = (newLocale: string, oldLocale: string) => {
    this.setState({
      languageChange: true,
    }, () => {
      this.setState({
        languageChange: false,
      })
    })
  };

  get authenticatedRedirectPath() {
    return '/reports';
  }

  get localizedRedirectPath() {
    const { location } = this.props;
    if (location) {
      const { pathname } = location;
      if (pathname.startsWith('/fr') || pathname.startsWith('/de')) {
        return {
          pathname: pathname.substring(3),
          search: `?locale=${pathname.substring(1, 3)}`
        };
      }
    }
    return '/login';
  }

  render() {
    const { initialized, authenticated } = this.props;
    if (initialized && !this.state.languageChange) {
      const loggedIn = authenticated;
      return (
        <Suspense fallback={<div>Loading</div>}>
          <Switch>
            <Route path='/login' render={() => (loggedIn ? <Redirect to={this.authenticatedRedirectPath} /> : <Login />)} />
            <Route path='/forgot_password' render={() => (loggedIn ? <Redirect to='/dashboard' /> : <Login />)} />
            <Route path='/reset/password' render={() => <Login />} />
            <Route path='/' render={() => (loggedIn ? <Application /> : <Redirect to={this.localizedRedirectPath} />)} />
          </Switch>
        </Suspense>
      );
    }
    return <div>Loading</div>;
  }
}

function mapStateToProps(state: AppState) {
  return {
    initialized: state.system.initialized,
    authenticated: state.system.authenticated,
  }
}

export default connect(mapStateToProps)(App);
