import {
  REPORTS_GET_REQUEST,
  REPORTS_GET_RESPONSE,
  REPORTS_LIST_REQUEST,
  REPORTS_LIST_RESPONSE,
  REPORTS_POST_COMMENTS_REQUEST,
  REPORTS_POST_COMMENTS_RESPONSE,
  REPORTS_POST_PROCESS_REQUEST,
  REPORTS_POST_PROCESS_RESPONSE,
  REPORTS_SEARCH,
  ReportsActionTypes,
  ReportsDomainState
} from "./reports.types";

const initialState = {
  approvedReports: [],
  availableReports: [],
  rejectedReports: [],
  fetchingList: false,
  fetchingAvailableList: false,
  fetchingApprovedList: false,
  fetchingRejectedList: false,
  fetchingGet: false,
  fetchingProcessing: false,
}

export function reportsDomainReducer(state: ReportsDomainState = initialState, action: ReportsActionTypes) {
  switch (action.type) {
    case REPORTS_LIST_REQUEST:
      let updatedFetching = {};
      switch (action.payload.filter) {
        case "AVAILABLE":
          updatedFetching.fetchingAvailableList = true;
          break;
        case "APPROVED":
          updatedFetching.fetchingApprovedList = true;
          break;
        case "REJECTED":
          updatedFetching.fetchingRejectedList = true;
          break;
        default:
          updatedFetching.fetchingList = true;
          break;
      }
      return {
        ...state,
        ...updatedFetching,
      };
    case REPORTS_LIST_RESPONSE:
        if (action.payload.error) {
          let updatedErrorInFetching = {};
          switch (action.payload.filter) {
            case "AVAILABLE":
              updatedErrorInFetching.fetchingAvailableList = false;
              break;
            case "APPROVED":
              updatedErrorInFetching.fetchingApprovedList = false;
              break;
            case "REJECTED":
              updatedErrorInFetching.fetchingRejectedList = false;
              break;
            default:
              updatedErrorInFetching.fetchingList = false;
              break;
          }
          return {
            ...state,
            ...updatedErrorInFetching,
            error: action.payload.error,
          }
        }
        const sortedReports = action.payload.reports.sort((r1, r2) => r2.allocatedAt.getTime() - r1.allocatedAt.getTime());
        let updatedReport = {};
        switch (action.payload.filter) {
          case "AVAILABLE":
            updatedReport.fetchingAvailableList = false;
            updatedReport.availableReports = sortedReports.filter(r => r.status === 'AVAILABLE').map(r => r.id);
            break;
          case "APPROVED":
            updatedReport.fetchingApprovedList = false;
            updatedReport.approvedReports = sortedReports.filter(r => r.status === 'APPROVED').map(r => r.id);
            break;
          case "REJECTED":
            updatedReport.fetchingRejectedList = false;
            updatedReport.rejectedReports = sortedReports.filter(r => r.status === 'REJECTED').map(r => r.id);
            break;
          default:
            updatedReport.fetchingList = false;
            updatedReport.availableReports = sortedReports.filter(r => r.status === 'AVAILABLE').map(r => r.id);
            updatedReport.approvedReports = sortedReports.filter(r => r.status === 'APPROVED').map(r => r.id);
            updatedReport.rejectedReports = sortedReports.filter(r => r.status === 'REJECTED').map(r => r.id);
            break;
        }
        return {
          ...state,
          ...updatedReport,
          error: null,
        }
    case REPORTS_GET_REQUEST:
      return {
        ...state,
        fetchingGet: true,
      }
    case REPORTS_GET_RESPONSE: {
      return {
        ...state,
        fetchingGet: false
      }
    }
    case REPORTS_POST_COMMENTS_REQUEST:
    case REPORTS_POST_PROCESS_REQUEST:
      return {
        ...state,
        fetchingProcessing: true,
      }
    case REPORTS_POST_COMMENTS_RESPONSE:
    case REPORTS_POST_PROCESS_RESPONSE:
      return {
        ...state,
        fetchingProcessing: false
      }
      case REPORTS_SEARCH:
        const reportsList = Object.values(action.payload.reports);
        const { search } = action.payload;
        return {
          ...state,
          availableReports: reportsList.filter(r => r.status === 'AVAILABLE' && r.patient.name.toLowerCase().includes(search.toLowerCase())).map(r => r.id),
          approvedReports: reportsList.filter(r => r.status === 'APPROVED' && r.patient.name.toLowerCase().includes(search.toLowerCase())).map(r => r.id),
          rejectedReports: reportsList.filter(r => r.status === 'REJECTED' && r.patient.name.toLowerCase().includes(search.toLowerCase())).map(r => r.id),
        }
    default:
      return state
  }
}
