import React, { Component } from 'react';
import { Alert, Button, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { AppState } from 'store';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import * as yup from 'yup';
import { Formik } from 'formik';

import { logScreen } from 'core/analytics';
import { UnauthorizedError } from 'core/util/errors';
import { SystemState } from 'store/system/system.types';
import { loginThunk } from 'store/system/system.actions';

import { Card } from '../components/Card';
import Container from '../components/Container';

import '../styles.scss';

const formSchema = yup.object({
  email: yup
    .string()
    .email()
    .required(),
  password: yup.string().required()
});

interface Props {
  system: SystemState;
  login(email: string, password: string): void;
  i18n: i18next.i18n;
  t: i18next.TFunction;
}

interface State {
  error: any;
}

class Login extends Component<Props, State> {
  state = {
    error: null
  };

  componentDidMount() {
    logScreen('LOGIN');
  }

  onSubmit = (values) => {
    this.props.login(values.email, values.password);
  };

  renderExpirationMessage() {
    if (this.props.system.expired) {
      return <Alert variant='warning'>Your session has expired, please log in again</Alert>;
    }
    return null;
  }

  renderErrorMessage() {
    const { system, t } = this.props;
    if (system.error) {
      if (system.error && system.error.name === UnauthorizedError.NAME) {
        return <Alert variant='warning'>{t('auth.login.messages.incorrect')}</Alert>;
      }
      return <Alert variant='warning'>{t('auth.login.messages.error')}</Alert>;
    }
    return null;
  }

  renderFooter = () => {
    const { t } = this.props;
    return (
      <React.Fragment>
        <a className='footerlink left' href='mailto:info@ectosense.com'>
          {t('auth.footer.no_account_yet')}
        </a>
        {/* <Link className='footerlink right' to='/forgot_password'>
          {t('auth.footer.forgot_password')}
        </Link> */}
      </React.Fragment>
    );
  };

  render() {
    const { system, t } = this.props;
    return (
      <Container>
        <Card title={t('auth.login.title')} footer={this.renderFooter}>
          <Formik
            validationSchema={formSchema}
            onSubmit={this.onSubmit}
            initialValues={{
              email: '',
              password: ''
            }}
          >
            {({ handleSubmit, handleChange, values, errors, submitCount }) => (
              <>
                {this.renderExpirationMessage()}
                {this.renderErrorMessage()}
                <Form noValidate onSubmit={handleSubmit}>
                  <Form.Group>
                    <Form.Label>{t('auth.login.email_input_label')}</Form.Label>
                    <Form.Control type='email' name='email' onChange={handleChange} value={values.email} isInvalid={submitCount > 0 && !!errors.email} />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>{t('input_labels.password')}</Form.Label>
                    <Form.Control type='password' name='password' onChange={handleChange} value={values.password} isInvalid={submitCount > 0 && !!errors.password}/>
                  </Form.Group>
                  <Button type='submit' block disabled={system.fetching}>
                    {t('auth.login.button')}
                  </Button>
                </Form>
              </>
            )}
          </Formik>
        </Card>
      </Container>
    );
  }
}

 const mapStateToProps = (state: AppState) => {
  return {
    system: state.system
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    login: (email, password) => {
      dispatch(loginThunk({email, password}));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Login));
